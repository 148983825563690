<template>
  <div class="aboutme-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/aboutme_header_icon.svg" alt srcset /> -->
      <svg class="about-me-comp-nav-svg" width="130" height="130" viewBox="0 0 130 130">
        <g id="Group_1290" data-name="Group 1290" transform="translate(-542 -39)">
          <g id="comment" transform="translate(531 28)">
            <path id="Path_2048" data-name="Path 2048"
              d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z" transform="translate(0 10.667)"
              fill="#6c63ff" />
            <path id="Path_2049" data-name="Path 2049"
              d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
              transform="translate(43.333 0.667)" />
            <path id="Path_2050" data-name="Path 2050"
              d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z"
              transform="translate(1.333 1.333)" fill="#6c63ff" />
            <path id="Path_2051" data-name="Path 2051"
              d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z"
              transform="translate(45.333 0)" />
            <path id="Path_2052" data-name="Path 2052"
              d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z"
              transform="translate(49.333 7.333)" fill="#6c63ff" />
            <path id="Path_2053" data-name="Path 2053"
              d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
              transform="translate(0 32)" />
            <path id="Path_2054" data-name="Path 2054"
              d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z" transform="translate(8 49.333)"
              fill="#6c63ff" />
            <path id="Path_2055" data-name="Path 2055"
              d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
              transform="translate(3.333 14.667)" fill="#fff" />
          </g>
          <g id="noun_profile_593208" transform="translate(558.703 55.704)">
            <path id="Path_2395" data-name="Path 2395"
              d="M58.644,44.544a.344.344,0,0,0,.344-.343V41.575a.345.345,0,0,0-.344-.344.968.968,0,0,1-.969-.969v-1.97c0-3.452-1.541-7.566-8.879-7.566-5.725,0-8.879,2.687-8.879,7.566v1.97a.968.968,0,0,1-.969.969.4.4,0,0,0-.344.344V44.2a.344.344,0,0,0,.344.343.971.971,0,0,1,.908.629A51.992,51.992,0,0,0,43.037,52.2l.109.165c.747,1.134,1.771,2.687,5.651,2.687s4.9-1.554,5.651-2.687l.108-.165a51.937,51.937,0,0,0,3.181-7.025A.971.971,0,0,1,58.644,44.544ZM48.8,11.3A37.5,37.5,0,1,0,86.3,48.8,37.5,37.5,0,0,0,48.8,11.3ZM40.918,59.613c-3.419,0-9.061,2-9.553,4.471l-.651,3.91a.97.97,0,0,1-.954.81.917.917,0,0,1-.161-.014.969.969,0,0,1-.8-1.114l.657-3.94c.8-3.991,7.883-6.061,11.459-6.061a.969.969,0,0,1,0,1.938ZM48.8,56.987c-4.923,0-6.392-2.228-7.269-3.558l-.1-.157a47.08,47.08,0,0,1-3.187-6.9A2.285,2.285,0,0,1,36.667,44.2V41.575a2.316,2.316,0,0,1,1.313-2.058V38.292c0-2.857,1.054-9.5,10.817-9.5s10.817,6.646,10.817,9.5v1.217a2.287,2.287,0,0,1,1.313,2.067V44.2a2.284,2.284,0,0,1-1.573,2.167,47.071,47.071,0,0,1-3.187,6.9l-.1.155C55.187,54.759,53.719,56.987,48.8,56.987Zm19.2,11.8a.916.916,0,0,1-.161.014.966.966,0,0,1-.953-.81l-.657-3.938c-.486-2.432-5.625-4.415-9.549-4.415a.969.969,0,1,1,0-1.938c4.3,0,10.672,2.085,11.454,6l.663,3.971A.969.969,0,0,1,67.994,68.79Z" />
          </g>
        </g>
      </svg>

      <div class="c-n-title">
        <span>ABOUT</span>
        <span>&nbsp;</span>
        <span>ME</span>
      </div>
    </div>

    <div class="am-c-content">
      <v-container fluid ma-0 pa-0>
        <v-row dense>
          <v-col class="pa-4 am-c-c-left" cols="12" sm="12" md="6" lg="6">
            <div class="am-c-c-img-bg">
              <!-- <img src="@/assets/icons/aboutme_bg_1.svg" alt srcset /> -->
              <svg class="about-me-person-svg" width="607.843" height="410" viewBox="0 0 607.843 410">
                <g id="Group_667" data-name="Group 667" transform="translate(0)">
                  <path id="Path_756" data-name="Path 756" d="M604.632,478.841H591.573V349.326l29.679-.605Z"
                    transform="translate(-158.158 -88.222)" />
                  <path id="Path_757" data-name="Path 757" d="M689.211,478.841H702.27V349.326l-29.679-.605Z"
                    transform="translate(-179.818 -88.222)" />
                  <path id="Path_758" data-name="Path 758"
                    d="M771.982,710.758,744.2,710.5a8.9,8.9,0,0,1-7.735-1.689A9.275,9.275,0,0,1,733,701.559h0a9.225,9.225,0,0,1,5.117-8.3l6.338-3.109,20.6-14.354,14.839,4.237,2.842,13.04A14.9,14.9,0,0,1,771.982,710.758Z"
                    transform="translate(-331.636 -302.124)" fill="#a2a2a2" />
                  <path id="Path_759" data-name="Path 759"
                    d="M874.064,710.543l-27.785-.26a8.9,8.9,0,0,1-7.735-1.689,9.274,9.274,0,0,1-3.464-7.251h0a9.225,9.225,0,0,1,5.117-8.3l6.338-3.109,20.6-14.354,14.839,4.236,2.842,13.04a14.9,14.9,0,0,1-10.751,17.683Z"
                    transform="translate(-358.928 -302.07)" fill="#a2a2a2" />
                  <path id="Path_760" data-name="Path 760"
                    d="M866.727,522.981l-40.912-3.724-5.387-48.929-12.063,47.34L767.007,513.9c1.525-28.989,4.806-53.17,12.465-65.363L866.134,432.8Z"
                    transform="translate(-340.728 -240.65)" fill="#6e6e6e" />
                  <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="22.106" cy="22.539" rx="22.106" ry="22.539"
                    transform="translate(462.547 10.657)" />
                  <path id="Path_761" data-name="Path 761"
                    d="M856.63,271.444,818.048,266.6c4.909-10.658,7.121-20.609,3.561-29.05h29.679C846.7,245.9,850.8,258.273,856.63,271.444Z"
                    transform="translate(-354.374 -191.253)" />
                  <path id="Path_762" data-name="Path 762"
                    d="M869.816,390.972c-27.149,20.3-56.777,27.221-89.036,19.972,19.573-45.562,16.778-90.467,0-134.962l30.866-13.315c8.655,8.608,18.58,8.8,29.679,1.21l30.272,6.657C866.059,314.924,864.2,356.153,869.816,390.972Z"
                    transform="translate(-344.41 -197.607)" />
                  <path id="Path_763" data-name="Path 763"
                    d="M821.782,189.094l-3.733-1.524s7.8-8.761,18.664-8l-3.054-3.428s7.466-3.047,14.253,4.952c3.568,4.205,7.7,9.148,10.269,14.716h4l-1.668,3.746,5.84,3.746-5.994-.673a21.119,21.119,0,0,1-.567,9.7l.161,2.961S853,204.331,853,202.808v3.809s-3.733-3.428-3.733-5.714l-2.036,2.666-1.018-4.19-12.556,4.19,2.036-3.428-7.8,1.143,3.054-4.19s-8.823,4.952-9.163,9.142-4.751,9.523-4.751,9.523l-2.036-3.809S811.941,194.808,821.782,189.094Z"
                    transform="translate(-353.438 -175.573)" />
                  <path id="Path_764" data-name="Path 764"
                    d="M858.9,487.948H724.824a5.185,5.185,0,0,1-5.129-5.229V461.8a5.185,5.185,0,0,1,5.129-5.229H858.9a5.186,5.186,0,0,1,5.129,5.229v20.916A5.186,5.186,0,0,1,858.9,487.948Z"
                    transform="translate(-328.079 -246.663)" />
                  <path id="Path_40" data-name="Path 40"
                    d="M752.465,471.627a4.443,4.443,0,0,0,0,8.884h93.842a4.443,4.443,0,0,0,.143-8.884H752.465Z"
                    transform="translate(-335.676 -250.471)" fill="#fff" />
                  <path id="Path_765" data-name="Path 765"
                    d="M791.565,423.378l6.815,12.387a11.191,11.191,0,0,1,.518,9.686,10.852,10.852,0,0,1-7.2,6.337h0a10.626,10.626,0,0,1-9.4-2,11.082,11.082,0,0,1-4.108-8.85l.32-16.951c-5.12-30.132-9.276-58.661-8.31-78.678h23.743l-4.749,23Z"
                    transform="translate(-341.544 -218.513)" />
                  <path id="Path_766" data-name="Path 766"
                    d="M898.02,423.739l-4.992,8.483a9.512,9.512,0,0,1-16.017.722h0a9.95,9.95,0,0,1-1.714-7.335,9.827,9.827,0,0,1,3.9-6.405l8.731-6.359,12.465-52.653L892.678,338.4l21.369-3.631C924.08,358.416,913.973,389.934,898.02,423.739Z"
                    transform="translate(-369.646 -215.849)" />
                  <path id="Path_767" data-name="Path 767"
                    d="M798.466,331.905l-31.459-1.816,2.161-34.37a21.617,21.617,0,0,1,15.811-21.443l3.99-1.077Z"
                    transform="translate(-340.728 -200.271)" />
                  <path id="Path_768" data-name="Path 768"
                    d="M920.859,320.355l-29.085,7.263v-56.89l1.2-.079A20.035,20.035,0,0,1,913.786,286.7Z"
                    transform="translate(-374.085 -199.615)" />
                  <rect id="Rectangle_113" data-name="Rectangle 113" width="444.055" height="1.494"
                    transform="translate(163.788 408.506)" fill="#505050" />
                  <path id="Path_769" data-name="Path 769"
                    d="M211.779,619.192a164.146,164.146,0,0,0,7.389,44.581c.1.335.211.665.319,1H247.06c-.029-.3-.059-.635-.088-1-1.839-21.551-12.439-152.714.235-175.145C246.1,490.448,209.641,550.715,211.779,619.192Z"
                    transform="translate(-192.263 -254.772)" />
                  <path id="Path_770" data-name="Path 770"
                    d="M206.759,690.869c.23.335.471.67.716,1H228.16c-.157-.285-.338-.62-.549-1-3.417-6.289-13.532-25.09-22.921-44.581-10.09-20.946-19.347-42.687-18.567-50.555C185.883,597.508,178.9,651.607,206.759,690.869Z"
                    transform="translate(-185.175 -281.869)" />
                  <path id="Path_771" data-name="Path 771"
                    d="M483.109,353.089H258.186a5.186,5.186,0,0,1-5.129-5.229V220.121a5.186,5.186,0,0,1,5.129-5.229H483.109a5.186,5.186,0,0,1,5.129,5.229V347.86A5.186,5.186,0,0,1,483.109,353.089Z"
                    transform="translate(-203.323 -185.52)" />
                  <path id="Path_772" data-name="Path 772"
                    d="M485.3,595.409H254.518a3.7,3.7,0,0,1-3.663-3.735V218.186a3.7,3.7,0,0,1,3.663-3.735H485.3a3.7,3.7,0,0,1,3.663,3.735V591.674a3.7,3.7,0,0,1-3.663,3.735ZM254.518,215.945a2.222,2.222,0,0,0-2.2,2.241V591.674a2.222,2.222,0,0,0,2.2,2.241H485.3a2.222,2.222,0,0,0,2.2-2.241V218.186a2.222,2.222,0,0,0-2.2-2.241Z"
                    transform="translate(-202.734 -185.409)" />
                  <ellipse id="Ellipse_36" data-name="Ellipse 36" cx="8.059" cy="8.217" rx="8.059" ry="8.217"
                    transform="translate(70.981 220.607)" />
                  <path id="Path_773" data-name="Path 773"
                    d="M474.26,492.266H340.186a5.186,5.186,0,0,1-5.129-5.229V466.121a5.186,5.186,0,0,1,5.129-5.229H474.26a5.186,5.186,0,0,1,5.129,5.229v20.916a5.186,5.186,0,0,1-5.129,5.229Z"
                    transform="translate(-225.246 -247.755)" />
                  <path id="Path_774" data-name="Path 774"
                    d="M479.122,503.386H334.79a.747.747,0,0,1,0-1.494H479.122a.747.747,0,0,1,0,1.494Z"
                    transform="translate(-224.978 -258.128)" fill="#3f3d56" />
                  <ellipse id="Ellipse_37" data-name="Ellipse 37" cx="8.059" cy="8.217" rx="8.059" ry="8.217"
                    transform="translate(70.981 269.909)" />
                  <path id="Path_775" data-name="Path 775"
                    d="M474.26,558.266H340.186a5.186,5.186,0,0,1-5.129-5.229V532.121a5.186,5.186,0,0,1,5.129-5.229H474.26a5.186,5.186,0,0,1,5.129,5.229v20.916a5.186,5.186,0,0,1-5.129,5.229Z"
                    transform="translate(-225.246 -264.453)" />
                  <path id="Path_776" data-name="Path 776"
                    d="M479.122,569.386H334.79a.747.747,0,0,1,0-1.494H479.122a.747.747,0,0,1,0,1.494Z"
                    transform="translate(-224.978 -274.826)" />
                  <ellipse id="Ellipse_38" data-name="Ellipse 38" cx="8.059" cy="8.217" rx="8.059" ry="8.217"
                    transform="translate(70.981 319.212)" />
                  <path id="Path_777" data-name="Path 777"
                    d="M395.867,624.267H340.186a5.186,5.186,0,0,1-5.129-5.229V598.121a5.186,5.186,0,0,1,5.129-5.229h55.681A5.186,5.186,0,0,1,401,598.121v20.916A5.186,5.186,0,0,1,395.867,624.267Z"
                    transform="translate(-225.245 -281.15)" />
                  <path id="Path_778" data-name="Path 778"
                    d="M400.728,635.386H334.79a.747.747,0,0,1,0-1.494h65.938a.747.747,0,0,1,0,1.494Z"
                    transform="translate(-224.978 -291.523)" />
                  <path id="Path_779" data-name="Path 779"
                    d="M502.867,624.267H447.186a5.185,5.185,0,0,1-5.129-5.229V598.121a5.186,5.186,0,0,1,5.129-5.229h55.681A5.186,5.186,0,0,1,508,598.121v20.916A5.186,5.186,0,0,1,502.867,624.267Z"
                    transform="translate(-253.852 -281.15)" />
                  <path id="Path_780" data-name="Path 780"
                    d="M507.728,635.386H441.79a.747.747,0,0,1,0-1.494h65.938a.747.747,0,0,1,0,1.494Z"
                    transform="translate(-253.585 -291.523)" />
                  <path id="Path_40-2" data-name="Path 40"
                    d="M291.827,268.446a4.443,4.443,0,0,0,0,8.884h175.9a4.443,4.443,0,0,0,.143-8.883H291.827Z"
                    transform="translate(-212.523 -199.069)" fill="#fff" />
                  <path id="Path_40-3" data-name="Path 40"
                    d="M291.827,301.446a4.443,4.443,0,0,0,0,8.884h175.9a4.443,4.443,0,0,0,.143-8.884H291.827Z"
                    transform="translate(-212.523 -207.417)" fill="#fff" />
                  <path id="Path_40-4" data-name="Path 40"
                    d="M291.827,334.446a4.443,4.443,0,0,0,0,8.884h175.9a4.443,4.443,0,0,0,.143-8.883H291.827Z"
                    transform="translate(-212.523 -215.766)" fill="#fff" />
                  <path id="Path_781" data-name="Path 781"
                    d="M518.765,683.509l-1.844-1.88a.423.423,0,0,0-.3-.128h-.9a.429.429,0,0,0-.4.27.444.444,0,0,0,.093.476l1.021,1.04H508.29a.747.747,0,0,0,0,1.494h8.149l-.917.934a.506.506,0,0,0-.106.544.489.489,0,0,0,.452.308h.724a.485.485,0,0,0,.346-.146l1.826-1.861A.753.753,0,0,0,518.765,683.509Z"
                    transform="translate(-271.364 -303.567)" />
                  <path id="Path_782" data-name="Path 782"
                    d="M511.78,692.8A11.954,11.954,0,1,1,523.5,680.845,11.839,11.839,0,0,1,511.78,692.8Zm0-22.41a10.46,10.46,0,1,0,10.257,10.458,10.359,10.359,0,0,0-10.257-10.458Z"
                    transform="translate(-269.359 -300.377)" />
                </g>
              </svg>
            </div>
            <div class="am-c-c-connect">
              <div class="am-c-c-c-chip">
                <svg width="52" height="52" x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" @click="onClickFB">
                  <path d="M59.5,1h-55C2.5,1,1,2.6,1,4.5v55c0,2,1.6,3.5,3.5,3.5h29.6V38.9h-8v-9.3h8v-6.9c0-8,4.8-12.4,12-12.4
	c2.4,0,4.8,0.1,7.2,0.4V19h-4.8c-3.8,0-4.6,1.8-4.6,4.5v5.9H53l-1.3,9.4h-8v23.8h15.8c2,0,3.5-1.5,3.5-3.5V4.5
	C62.9,2.5,61.3,1,59.5,1z" />
                </svg>

                <svg width="52" height="52" x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve"
                  @click="onClickInsta">
                  <g>
                    <ellipse cx="32.1" cy="32" rx="11.6" ry="11.6" />
                    <path d="M44.7,1H19.3C9.2,1,1,9.2,1,19.3v25.2C1,54.8,9.2,63,19.3,63h25.2C54.8,63,63,54.8,63,44.7V19.3C63,9.2,54.8,1,44.7,1z
		 M32.1,47.2c-8.5,0-15.2-6.9-15.2-15.2s6.8-15.2,15.2-15.2c8.3,0,15.1,6.9,15.1,15.2S40.5,47.2,32.1,47.2z M53.1,18.2
		c-1,1.1-2.5,1.7-4.2,1.7c-1.5,0-3-0.6-4.2-1.7C43.6,17.1,43,15.7,43,14s0.6-3,1.7-4.2C45.8,8.6,47.2,8,48.9,8
		c1.5,0,3.1,0.6,4.2,1.7c1,1.3,1.7,2.8,1.7,4.4C54.7,15.7,54.1,17.1,53.1,18.2z" />
                    <path
                      d="M49,11.6c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4S50.4,11.6,49,11.6z" />
                  </g>
                </svg>
              </div>
            </div>
          </v-col>
          <v-col class="px-4" cols="12" sm="12" md="6" lg="6">
            <div class="am-c-c-desc">
              <p>Hi, I’m Naveen DB</p>
              <span>&nbsp;</span>
              <p>Currently working in Accenture AI, Bangalore. And I specialise in User Interface design and
                development.</p>
              <span>&nbsp;</span>
              <p>
                Born in Sagara, Shimoga and raised in Bangalore and graduated from Cambridge Institute of technology
                (Bangalore) with a major in
                <span class="font-weight-bold">Computer Science Engineering (CSE)</span>
              </p>
              <span>&nbsp;</span>
              <p>
                Basically I love to sketch and this interest brought me into UI/UX and focused mainly into front end
                coding right from beginning. Over the years have been learning best practises for coding, implementing
                it, and learning niche technologies to address the present industry needs like
                <span class="font-weight-bold">performance, speed, responsiveness</span> etc.
              </p>
              <span>&nbsp;</span>
              <p>
                After the graduation in the year 2015, landed into a start up company called
                <span class="font-weight-bold">Flutura Decision Science and Analytics</span>.
                Worked there for close to 2 years and switched into an AI based start up company called
                <span class="font-weight-bold">Kogentix INC</span>. Worked for almost 2 years and later on, the company
                got acquired by
                <span class="font-weight-bold">Accenture</span>.
              </p>

              <div class="am-c-c-contact">
                <!-- <img src="@/assets/icons/aboutme_contact.svg" alt srcset /> -->
                <svg class="about-me-contact-svg" width="579.5" height="238.001" viewBox="0 0 579.5 238.001">
                  <g id="Group_969" data-name="Group 969" transform="translate(-992 -775)">
                    <g id="Group_940" data-name="Group 940" transform="translate(665.054 -46)">
                      <g id="Group_931" data-name="Group 931" transform="translate(550.77 834.725)" opacity="0.1">
                        <ellipse id="Ellipse_40" data-name="Ellipse 40" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.58 105.539)" />
                        <path id="Path_875" data-name="Path 875"
                          d="M530.755,445.229a4.631,4.631,0,0,0,1.253-3.126c0-2.824-2.685-5.113-6-5.113s-6.012,2.3-6.012,5.12a4.632,4.632,0,0,0,1.253,3.126,4.522,4.522,0,0,0,0,6.248,4.522,4.522,0,0,0,0,6.248A4.617,4.617,0,0,0,520,460.858c0,2.82,2.685,5.113,5.991,5.113s6-2.292,6-5.113a4.616,4.616,0,0,0-1.253-3.126,4.522,4.522,0,0,0,0-6.248,4.522,4.522,0,0,0,0-6.248Z"
                          transform="translate(-497.44 -356.451)" />
                        <ellipse id="Ellipse_41" data-name="Ellipse 41" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.58 74.297)" fill="#3f3d56" />
                        <ellipse id="Ellipse_42" data-name="Ellipse 42" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.58 68.049)" fill="#3f3d56" />
                        <path id="Path_876" data-name="Path 876"
                          d="M483.293,232.669a20.257,20.257,0,0,1-2.232-2.141l15.741-1.685-17.026.082a12.9,12.9,0,0,1-.527-14.823l22.843,7.724-21.058-10.095c5.337-6.116,15.487-9.763,26.271-9.44s20.378,4.561,24.832,10.97,3.017,13.9-3.721,19.4a19.8,19.8,0,0,1,3.275,3.411l-20.443,6.938,21.79-4.766c3.112,5.99,1.371,12.6-4.628,17.568,9.257,7.669,7.914,18.687-3.083,25.308s-27.955,6.621-38.952,0-12.339-17.639-3.083-25.308c-8.192-6.787-8.192-16.347,0-23.134Z"
                          transform="translate(-477.149 -202.271)" fill="#ec615b" />
                        <path id="Path_877" data-name="Path 877"
                          d="M534.555,324.042c.01,4.2-2.155,8.273-6.144,11.567,9.257,7.669,7.915,18.687-3.083,25.308s-27.955,6.621-38.952,0-12.339-17.639-3.083-25.308C479.445,332.425,534.555,321.942,534.555,324.042Z"
                          transform="translate(-477.149 -282.078)" opacity="0.1" />
                      </g>
                      <g id="Group_932" data-name="Group 932" transform="translate(627.934 851.824)" opacity="0.1">
                        <ellipse id="Ellipse_43" data-name="Ellipse 43" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 78.802)" />
                        <path id="Path_878" data-name="Path 878"
                          d="M663.783,433.476a3.452,3.452,0,0,0,.932-2.333c0-2.11-2.006-3.822-4.475-3.822s-4.48,1.716-4.48,3.822a3.432,3.432,0,0,0,.937,2.333,3.378,3.378,0,0,0,0,4.67,3.378,3.378,0,0,0,0,4.67,3.432,3.432,0,0,0-.937,2.333c0,2.11,2.006,3.822,4.48,3.822s4.475-1.716,4.475-3.822a3.452,3.452,0,0,0-.932-2.333,3.4,3.4,0,0,0,0-4.67A3.4,3.4,0,0,0,663.783,433.476Z"
                          transform="translate(-638.892 -367.198)" />
                        <ellipse id="Ellipse_44" data-name="Ellipse 44" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 55.455)" fill="#3f3d56" />
                        <ellipse id="Ellipse_45" data-name="Ellipse 45" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 50.785)" fill="#3f3d56" />
                        <path id="Path_879" data-name="Path 879"
                          d="M628.311,274.752a14.9,14.9,0,0,1-1.669-1.6l11.766-1.259-12.725.062a9.647,9.647,0,0,1-.411-11.08l17.073,5.775L626.6,259.1c4.014-4.538,11.583-7.233,19.61-6.983s15.167,3.4,18.5,8.17,2.3,10.347-2.684,14.459a14.957,14.957,0,0,1,2.448,2.549l-15.267,5.171,16.284-3.562c2.324,4.476,1.023,9.414-3.459,13.128,6.926,5.729,5.93,13.967-2.289,18.918s-20.9,4.951-29.115,0-9.216-13.188-2.289-18.918c-6.122-5.072-6.122-12.215,0-17.287Z"
                          transform="translate(-623.72 -252.103)" />
                        <path id="Path_880" data-name="Path 880"
                          d="M666.625,342.906c.007,3.136-1.611,6.182-4.591,8.643,6.926,5.729,5.93,13.967-2.289,18.918s-20.9,4.951-29.115,0-9.215-13.188-2.289-18.918C625.456,349.172,666.625,341.338,666.625,342.906Z"
                          transform="translate(-623.729 -311.614)" opacity="0.1" />
                      </g>
                      <g id="Group_933" data-name="Group 933" transform="translate(844.742 821)" opacity="0.1">
                        <ellipse id="Ellipse_46" data-name="Ellipse 46" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.985 105.539)" />
                        <path id="Path_881" data-name="Path 881"
                          d="M1080.443,405.229a4.632,4.632,0,0,1-1.253-3.126c0-2.824,2.685-5.113,6-5.113s5.991,2.289,5.991,5.113a4.631,4.631,0,0,1-1.253,3.126,4.523,4.523,0,0,1,0,6.248,4.523,4.523,0,0,1,0,6.248,4.617,4.617,0,0,1,1.253,3.126c0,2.821-2.685,5.113-5.991,5.113s-6-2.292-6-5.113a4.616,4.616,0,0,1,1.253-3.126,4.522,4.522,0,0,1,0-6.248A4.522,4.522,0,0,1,1080.443,405.229Z"
                          transform="translate(-1056.21 -316.451)" />
                        <ellipse id="Ellipse_47" data-name="Ellipse 47" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.985 74.297)" fill="#3f3d56" />
                        <ellipse id="Ellipse_48" data-name="Ellipse 48" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.985 68.049)" fill="#3f3d56" />
                        <path id="Path_882" data-name="Path 882"
                          d="M1086.944,192.669a20.235,20.235,0,0,0,2.232-2.141l-15.741-1.685,17.026.082a12.9,12.9,0,0,0,.526-14.823l-22.843,7.724,21.058-10.095c-5.337-6.116-15.487-9.763-26.27-9.44s-20.378,4.561-24.833,10.97-3.017,13.9,3.722,19.4a19.8,19.8,0,0,0-3.275,3.411l20.443,6.938-21.79-4.766c-3.112,5.989-1.371,12.6,4.627,17.568-9.256,7.669-7.914,18.687,3.083,25.308s27.955,6.621,38.952,0,12.339-17.639,3.083-25.308c8.192-6.787,8.192-16.347,0-23.134Z"
                          transform="translate(-1035.541 -162.271)" />
                        <path id="Path_883" data-name="Path 883"
                          d="M1035.81,284.042c-.01,4.2,2.155,8.273,6.144,11.567-9.257,7.669-7.915,18.687,3.082,25.308s27.955,6.621,38.952,0,12.339-17.639,3.083-25.308C1090.919,292.425,1035.81,281.942,1035.81,284.042Z"
                          transform="translate(-1035.668 -242.078)" opacity="0.1" />
                      </g>
                      <g id="Group_934" data-name="Group 934" transform="translate(794.314 849.765)" opacity="0.1">
                        <ellipse id="Ellipse_49" data-name="Ellipse 49" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(17.085 78.802)" />
                        <path id="Path_884" data-name="Path 884"
                          d="M973.162,427.476a3.453,3.453,0,0,1-.932-2.333c0-2.11,2.006-3.822,4.475-3.822s4.48,1.716,4.48,3.822a3.432,3.432,0,0,1-.937,2.333,3.378,3.378,0,0,1,0,4.67,3.378,3.378,0,0,1,0,4.67,3.432,3.432,0,0,1,.937,2.333c0,2.11-2.006,3.823-4.48,3.823s-4.475-1.716-4.475-3.823a3.453,3.453,0,0,1,.932-2.333,3.4,3.4,0,0,1,0-4.67A3.4,3.4,0,0,1,973.162,427.476Z"
                          transform="translate(-955.134 -361.198)" />
                        <ellipse id="Ellipse_50" data-name="Ellipse 50" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(17.085 55.455)" fill="#3f3d56" />
                        <ellipse id="Ellipse_51" data-name="Ellipse 51" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(17.085 50.785)" fill="#3f3d56" />
                        <path id="Path_885" data-name="Path 885"
                          d="M978.085,268.752a14.9,14.9,0,0,0,1.669-1.6l-11.766-1.259,12.724.062a9.647,9.647,0,0,0,.411-11.08l-17.073,5.775L979.8,253.1c-4.014-4.538-11.583-7.233-19.61-6.983s-15.167,3.4-18.5,8.17-2.3,10.347,2.683,14.459a14.955,14.955,0,0,0-2.448,2.549l15.267,5.171-16.252-3.558c-2.324,4.476-1.023,9.414,3.459,13.128-6.926,5.729-5.93,13.967,2.289,18.918s20.9,4.951,29.115,0,9.215-13.188,2.289-18.918c6.122-5.072,6.122-12.215,0-17.287Z"
                          transform="translate(-939.757 -246.103)" />
                        <path id="Path_886" data-name="Path 886"
                          d="M939.79,336.906c-.007,3.136,1.61,6.182,4.591,8.643-6.926,5.729-5.93,13.967,2.289,18.918s20.9,4.951,29.115,0,9.216-13.188,2.289-18.918C980.959,343.172,939.79,335.338,939.79,336.906Z"
                          transform="translate(-939.767 -305.614)" opacity="0.1" />
                      </g>
                      <g id="Group_935" data-name="Group 935" transform="translate(330.794 821.058)" opacity="0.1">
                        <ellipse id="Ellipse_52" data-name="Ellipse 52" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.586 105.482)" fill="#3f3d56" />
                        <path id="Path_887" data-name="Path 887"
                          d="M112.939,405.229a4.631,4.631,0,0,0,1.253-3.126c0-2.824-2.685-5.113-5.991-5.113s-5.991,2.289-5.991,5.113a4.627,4.627,0,0,0,1.248,3.126,4.534,4.534,0,0,0,0,6.248,4.534,4.534,0,0,0,0,6.248,4.61,4.61,0,0,0-1.248,3.126c0,2.821,2.68,5.113,5.991,5.113s6.007-2.285,6.007-5.106a4.616,4.616,0,0,0-1.253-3.126,4.522,4.522,0,0,0,0-6.248,4.522,4.522,0,0,0,0-6.248Z"
                          transform="translate(-79.624 -316.509)" />
                        <ellipse id="Ellipse_53" data-name="Ellipse 53" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.586 74.24)" fill="#3f3d56" />
                        <ellipse id="Ellipse_54" data-name="Ellipse 54" cx="5.991" cy="5.113" rx="5.991" ry="5.113"
                          transform="translate(22.586 67.991)" fill="#3f3d56" />
                        <path id="Path_888" data-name="Path 888"
                          d="M65.464,192.779a20.726,20.726,0,0,1-2.232-2.141l15.746-1.685-17.041.1a12.9,12.9,0,0,1-.526-14.823l22.848,7.724L63.2,171.858c5.35-6.1,15.49-9.727,26.255-9.4s20.341,4.558,24.8,10.954,3.038,13.879-3.669,19.376a19.8,19.8,0,0,1,3.275,3.411l-20.448,6.921,21.8-4.766c3.1,5.99,1.362,12.6-4.633,17.568,9.257,7.669,7.914,18.687-3.083,25.308s-27.955,6.621-38.952,0S56.2,223.592,65.453,215.923c-8.192-6.787-8.192-16.347,0-23.134Z"
                          transform="translate(-59.309 -162.439)" />
                        <path id="Path_889" data-name="Path 889"
                          d="M116.715,284.042c.01,4.2-2.155,8.273-6.144,11.567,9.256,7.669,7.914,18.687-3.083,25.308s-27.955,6.621-38.952,0S56.2,303.278,65.453,295.609C61.6,292.425,116.715,281.942,116.715,284.042Z"
                          transform="translate(-59.309 -242.135)" opacity="0.1" />
                      </g>
                      <g id="Group_936" data-name="Group 936" transform="translate(395.85 849.765)" opacity="0.1">
                        <ellipse id="Ellipse_55" data-name="Ellipse 55" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 78.802)" />
                        <path id="Path_890" data-name="Path 890"
                          d="M222.953,427.476a3.453,3.453,0,0,0,.932-2.333c0-2.11-2-3.822-4.475-3.822s-4.48,1.716-4.48,3.822a3.457,3.457,0,0,0,.937,2.333,3.378,3.378,0,0,0,0,4.67,3.378,3.378,0,0,0,0,4.67,3.457,3.457,0,0,0-.937,2.333c0,2.11,2.006,3.822,4.48,3.822s4.475-1.716,4.475-3.823a3.453,3.453,0,0,0-.932-2.333,3.4,3.4,0,0,0,0-4.67,3.4,3.4,0,0,0,0-4.67Z"
                          transform="translate(-198.057 -361.198)" />
                        <ellipse id="Ellipse_56" data-name="Ellipse 56" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 55.456)" fill="#3f3d56" />
                        <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="4.48" cy="3.819" rx="4.48" ry="3.819"
                          transform="translate(16.873 50.786)" fill="#3f3d56" />
                        <path id="Path_891" data-name="Path 891"
                          d="M187.471,268.752a15.36,15.36,0,0,1-1.669-1.6l11.766-1.259-12.725.062a9.647,9.647,0,0,1-.411-11.08l17.073,5.775L185.76,253.1c4.014-4.538,11.583-7.233,19.61-6.983s15.167,3.4,18.5,8.17,2.3,10.347-2.683,14.459a14.56,14.56,0,0,1,2.448,2.549l-15.267,5.171,16.283-3.562c2.324,4.476,1.023,9.414-3.459,13.128,6.926,5.729,5.93,13.967-2.289,18.918s-20.9,4.951-29.115,0-9.215-13.188-2.289-18.918c-6.122-5.072-6.122-12.215,0-17.287Z"
                          transform="translate(-182.88 -246.103)" fill="#ec615b" />
                        <path id="Path_892" data-name="Path 892"
                          d="M225.785,336.906c.007,3.136-1.61,6.182-4.591,8.643,6.926,5.729,5.93,13.967-2.289,18.918s-20.9,4.951-29.115,0-9.215-13.188-2.289-18.918C184.605,343.172,225.785,335.338,225.785,336.906Z"
                          transform="translate(-182.889 -305.614)" opacity="0.1" />
                      </g>
                      <ellipse id="Ellipse_58" data-name="Ellipse 58" cx="288.5" cy="3.04" rx="288.5" ry="3.04"
                        transform="translate(326.946 987.29)" opacity="0.1" />
                      <ellipse id="Ellipse_60" data-name="Ellipse 60" cx="288.5" cy="3.04" rx="288.5" ry="3.04"
                        transform="translate(326.946 935.193)" opacity="0.1" />
                      <ellipse id="Ellipse_61" data-name="Ellipse 61" cx="288.5" cy="3.04" rx="288.5" ry="3.04"
                        transform="translate(326.946 961.24)" opacity="0.1" />
                      <path id="Path_893" data-name="Path 893"
                        d="M834.826,257a14.449,14.449,0,0,0-5.58.4,12.326,12.326,0,0,1-6.707,0,14.055,14.055,0,0,0-7.9.1,7.027,7.027,0,0,1-2.269.371A6.383,6.383,0,0,1,805.96,253a5.461,5.461,0,0,0,1.58-1.122,11.607,11.607,0,0,1,16.041-.038,7.363,7.363,0,0,0,5.312,1.9h.084A6.557,6.557,0,0,1,834.826,257Z"
                        transform="translate(-82.085 601.996)" opacity="0.1" />
                      <path id="Path_894" data-name="Path 894"
                        d="M844.561,233.468l-5.17,2.134,3.159-3.719a6.983,6.983,0,0,0-3.127-.724h-.084a9.112,9.112,0,0,1-1.053-.051l-1.779.724.753-.892a6.616,6.616,0,0,1-3.07-1.517l-3.159,1.3,1.98-2.347a11.41,11.41,0,0,0-7.023-2.313,11.106,11.106,0,0,0-8.044,3.232,7.227,7.227,0,0,1-5.312,1.867h-.174a5.967,5.967,0,1,0,0,11.883,7.144,7.144,0,0,0,2.269-.371,14.038,14.038,0,0,1,7.9-.1,12.243,12.243,0,0,0,6.707,0,14.1,14.1,0,0,1,7.823.1,7.079,7.079,0,0,0,2.243.364,6.251,6.251,0,0,0,6.518-5.943A5.554,5.554,0,0,0,844.561,233.468Z"
                        transform="translate(-82.074 616.828)" opacity="0.1" />
                      <path id="Path_895" data-name="Path 895"
                        d="M425.1,256.39a10.139,10.139,0,0,0-3.912.278,8.6,8.6,0,0,1-4.691,0,9.808,9.808,0,0,0-5.522.072,5.04,5.04,0,0,1-1.579.257,4.474,4.474,0,0,1-4.491-3.407,3.857,3.857,0,0,0,1.121-.786,8.128,8.128,0,0,1,11.229-.027,5.164,5.164,0,0,0,3.722,1.331h.058A4.609,4.609,0,0,1,425.1,256.39Z"
                        transform="translate(107.834 600.748)" opacity="0.1" />
                      <path id="Path_896" data-name="Path 896"
                        d="M431.9,239.928l-3.617,1.5,2.2-2.6a4.856,4.856,0,0,0-2.185-.508h-.063a6.013,6.013,0,0,1-.748-.038l-1.227.508.526-.624a4.661,4.661,0,0,1-2.148-1.06l-2.2.906,1.39-1.64a7.973,7.973,0,0,0-4.917-1.623A7.757,7.757,0,0,0,413.288,237a5.068,5.068,0,0,1-3.722,1.307h-.121a4.177,4.177,0,1,0,0,8.317,4.941,4.941,0,0,0,1.579-.261,9.826,9.826,0,0,1,5.517-.069,8.45,8.45,0,0,0,4.7.021,9.864,9.864,0,0,1,5.475.069,4.938,4.938,0,0,0,1.579.254,4.375,4.375,0,0,0,4.559-4.159,3.878,3.878,0,0,0-.948-2.556Z"
                        transform="translate(107.843 611.126)" opacity="0.1" />
                      <path id="Path_897" data-name="Path 897"
                        d="M321.59,334.8a10.123,10.123,0,0,1,3.906.278,8.617,8.617,0,0,0,4.7,0,9.842,9.842,0,0,1,5.522.069,4.916,4.916,0,0,0,1.579.261A4.466,4.466,0,0,0,341.78,332a3.766,3.766,0,0,1-1.116-.786,8.139,8.139,0,0,0-11.235-.024,5.162,5.162,0,0,1-3.717,1.331h-.058A4.639,4.639,0,0,0,321.59,334.8Z"
                        transform="translate(147.286 549.243)" opacity="0.1" />
                      <path id="Path_898" data-name="Path 898"
                        d="M307.791,318.375l3.622,1.5-2.2-2.6a4.88,4.88,0,0,1,2.19-.511h.058a6.846,6.846,0,0,0,.753-.034l1.227.5-.526-.621a4.649,4.649,0,0,0,2.143-1.06l2.2.906-1.369-1.644a7.971,7.971,0,0,1,4.917-1.62,7.766,7.766,0,0,1,5.628,2.265,5.066,5.066,0,0,0,3.717,1.307h.126a4.176,4.176,0,1,1,0,8.317,4.939,4.939,0,0,1-1.579-.257,9.809,9.809,0,0,0-5.523-.072,8.361,8.361,0,0,1-2.358.343,8.5,8.5,0,0,1-2.338-.323,9.847,9.847,0,0,0-5.47.069,4.937,4.937,0,0,1-1.579.254,4.374,4.374,0,0,1-4.564-4.159A3.892,3.892,0,0,1,307.791,318.375Z"
                        transform="translate(154.261 559.595)" opacity="0.1" />
                    </g>
                    <g id="Group_937" data-name="Group 937" transform="translate(178.987 -136.625)">
                      <path id="Path_959" data-name="Path 959"
                        d="M17.492,0A17.492,17.492,0,1,1,0,17.492,17.492,17.492,0,0,1,17.492,0Z"
                        transform="translate(834.645 952.81)" fill="#3f3d56" opacity="0.998" />
                      <path id="Path_926" data-name="Path 926"
                        d="M801.652,367.732a13.253,13.253,0,0,0-2.8-1.871c-.838-.4-1.146-.4-1.739.033-.493.35-.814.688-1.381.563a8.312,8.312,0,0,1-2.777-2.054,8.071,8.071,0,0,1-2.051-2.775c-.121-.576.212-.889.565-1.382.427-.593.439-.9.033-1.739a13.416,13.416,0,0,0-1.872-2.8c-.614-.612-.747-.478-1.083-.35a6.266,6.266,0,0,0-.992.525,2.994,2.994,0,0,0-1.19,1.258c-.24.511-.511,1.459.884,3.943a21.994,21.994,0,0,0,3.867,5.157h0a22.029,22.029,0,0,0,5.175,3.874c2.481,1.4,3.432,1.122,3.943.884a2.938,2.938,0,0,0,1.256-1.19,6.176,6.176,0,0,0,.525-.992C802.129,368.48,802.266,368.343,801.652,367.732Z"
                        transform="translate(57.994 607.081)" fill="#eee" />
                      <path id="Path_927" data-name="Path 927"
                        d="M749.188,300.86a17.5,17.5,0,1,0,17.49,17.493A17.5,17.5,0,0,0,749.188,300.86Zm0,32.657a15.166,15.166,0,1,1,15.167-15.166A15.167,15.167,0,0,1,749.188,333.517Z"
                        transform="translate(102.949 651.951)" opacity="0.1" />
                    </g>
                    <g id="Group_938" data-name="Group 938" transform="translate(306.962 -136.625)">
                      <ellipse id="Ellipse_64" data-name="Ellipse 64" cx="17.492" cy="17.493" rx="17.492" ry="17.493"
                        transform="translate(956.603 952.815)" fill="#3f3d56" />
                      <path id="Path_931" data-name="Path 931"
                        d="M331.973,430.16,325,446.25l.651.651,6.324-2.789L338.3,446.9l.651-.651Z"
                        transform="translate(642.122 531.777)" fill="#eee" />
                    </g>
                    <g id="Group_939" data-name="Group 939" transform="translate(430.18 -143)">
                      <ellipse id="Ellipse_63" data-name="Ellipse 63" cx="17.382" cy="17.492" rx="17.382" ry="17.492"
                        transform="translate(1066.096 952.807)" fill="#3f3d56" />
                      <path id="Path_928" data-name="Path 928"
                        d="M564.184,522.265l-5.2,5.329a.095.095,0,0,0,0,.137l3.652,3.908a.636.636,0,0,1,.135.688.624.624,0,0,1-1.024.206l-3.633-3.894a.1.1,0,0,0-.139,0l-.885.9a3.878,3.878,0,0,1-2.782,1.178,3.963,3.963,0,0,1-2.831-1.211l-.85-.875a.1.1,0,0,0-.139,0l-3.624,3.885a.625.625,0,0,1-.889,0,.633.633,0,0,1,0-.894l3.638-3.9a.105.105,0,0,0,0-.136l-5.2-5.329a.1.1,0,0,0-.1-.021.1.1,0,0,0-.059.089v10.662a1.556,1.556,0,0,0,1.546,1.556H562.8a1.556,1.556,0,0,0,1.546-1.556V522.333a.1.1,0,0,0-.06-.093A.1.1,0,0,0,564.184,522.265Z"
                        transform="translate(529.18 442.749)" fill="#eee" />
                      <path id="Path_929" data-name="Path 929"
                        d="M557.115,521.532a2.624,2.624,0,0,0,1.9-.787l7.58-7.764a1.507,1.507,0,0,0-.956-.35H548.606a1.5,1.5,0,0,0-.956.35l7.582,7.764A2.614,2.614,0,0,0,557.115,521.532Z"
                        transform="translate(526.363 450.667)" fill="#eee" />
                      <path id="Path_930" data-name="Path 930"
                        d="M518.168,452.69a17.5,17.5,0,1,0,17.381,17.493A17.444,17.444,0,0,0,518.168,452.69Zm0,32.657a15.167,15.167,0,1,1,15.071-15.167A15.119,15.119,0,0,1,518.168,485.347Z"
                        transform="translate(565.091 500.118)" opacity="0.1" />
                    </g>
                    <g id="Group_753" data-name="Group 753" transform="translate(1073.744 959.341)">
                      <g id="Group_752" data-name="Group 752">
                        <path id="Path_843" data-name="Path 843"
                          d="M544.653,428a1.542,1.542,0,0,1,1.26-2.748,1.514,1.514,0,0,1,1.294,1.4,1.724,1.724,0,0,1-1.617,1.642c-.323.016-.323.5,0,.488a2.234,2.234,0,0,0,2.128-1.919,1.98,1.98,0,0,0-1.668-2.065,2.035,2.035,0,0,0-1.651,3.643c.255.179.511-.244.255-.439Z"
                          transform="translate(-543.535 -424.74)" fill="#5222d0" />
                      </g>
                    </g>
                    <g id="Group_771" data-name="Group 771" transform="translate(1009.454 962.698)">
                      <g id="Group_770" data-name="Group 770" transform="translate(0 0)">
                        <path id="Path_852" data-name="Path 852"
                          d="M166.913,447.6a.914.914,0,0,1,.715-1.675c1.294.358.851,1.951-.289,2.179-.323.065-.187.537.136.472a1.6,1.6,0,0,0,.153-3.155A1.4,1.4,0,0,0,166.64,448c.306.163.562-.26.272-.407Z"
                          transform="translate(-165.89 -445.381)" />
                      </g>
                    </g>
                    <path id="Path_853" data-name="Path 853"
                      d="M149.285,528.016c-.034,6.57-.051,13.156-.085,19.725q46.883-.171,93.767-.325c-.085-6.472-.187-12.944-.272-19.416Q195.99,528,149.285,528.016Z"
                      transform="translate(857.413 448.133)" />
                    <g id="Group_773" data-name="Group 773" transform="translate(1006 975.625)">
                      <g id="Group_772" data-name="Group 772">
                        <path id="Path_854" data-name="Path 854"
                          d="M145.6,525.351v19.107a.512.512,0,0,0,1.021,0V525.351a.512.512,0,0,0-1.021,0Z"
                          transform="translate(-145.6 -524.875)" />
                      </g>
                    </g>
                    <g id="Group_775" data-name="Group 775" transform="translate(1006.489 975.385)">
                      <g id="Group_774" data-name="Group 774">
                        <path id="Path_855" data-name="Path 855"
                          d="M148.973,524.376h93.6a.488.488,0,1,0,0-.976h-93.6a.488.488,0,1,0,0,.976Z"
                          transform="translate(-148.475 -523.4)" />
                      </g>
                    </g>
                    <g id="Group_777" data-name="Group 777" transform="translate(1100.021 975.394)">
                      <g id="Group_776" data-name="Group 776">
                        <path id="Path_856" data-name="Path 856"
                          d="M697.9,523.913q.128,9.952.255,19.92a.512.512,0,0,0,1.021,0q-.128-9.952-.255-19.92a.513.513,0,0,0-1.021,0Z"
                          transform="translate(-697.9 -523.45)" />
                      </g>
                    </g>
                    <g id="Group_779" data-name="Group 779" transform="translate(1006.013 995.176)">
                      <g id="Group_778" data-name="Group 778">
                        <path id="Path_857" data-name="Path 857"
                          d="M146.173,646.076h94.311a.488.488,0,1,0,0-.976H146.173a.488.488,0,1,0,0,.976Z"
                          transform="translate(-145.675 -645.1)" />
                      </g>
                    </g>
                    <g id="Group_961" data-name="Group 961">
                      <g id="Group_807" data-name="Group 807"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1106.83, 1000.78)">
                        <g id="Group_806" data-name="Group 806">
                          <path id="Path_872" data-name="Path 872"
                            d="M778.586,568a8.046,8.046,0,0,1,3.643-2.716c.306-.114.17-.585-.136-.472a8.64,8.64,0,0,0-3.95,2.943c-.2.26.238.5.443.244Z"
                            transform="translate(-778.091 -564.792)" />
                        </g>
                      </g>
                      <g id="Group_809" data-name="Group 809"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1109.842, 1003.268)">
                        <g id="Group_808" data-name="Group 808" transform="translate(0)">
                          <path id="Path_873" data-name="Path 873"
                            d="M762.213,554.18a10.757,10.757,0,0,1,7.286-5.757.245.245,0,1,0-.136-.472,11.286,11.286,0,0,0-7.593,5.968c-.136.309.29.553.443.26Z"
                            transform="translate(-761.744 -547.942)" />
                        </g>
                      </g>
                      <g id="Group_811" data-name="Group 811"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1112.411, 1005.159)">
                        <g id="Group_810" data-name="Group 810" transform="translate(0)">
                          <path id="Path_874" data-name="Path 874"
                            d="M748.176,543.841a9.483,9.483,0,0,1,3.49-5.692c1.736-1.415,4.409-2.943,6.776-2.651.323.032.323-.455,0-.488-2.417-.293-5.107,1.22-6.929,2.618a9.977,9.977,0,0,0-3.83,6.066c-.051.325.443.455.494.146Z"
                            transform="translate(-747.678 -534.974)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_753-2" data-name="Group 753" transform="translate(1306.314 957.341)">
                      <g id="Group_752-2" data-name="Group 752">
                        <path id="Path_843-2" data-name="Path 843"
                          d="M544.653,428a1.542,1.542,0,0,1,1.26-2.748,1.514,1.514,0,0,1,1.294,1.4,1.724,1.724,0,0,1-1.617,1.642c-.323.016-.323.5,0,.488a2.234,2.234,0,0,0,2.128-1.919,1.98,1.98,0,0,0-1.668-2.065,2.035,2.035,0,0,0-1.651,3.643c.255.179.511-.244.255-.439Z"
                          transform="translate(-543.535 -424.74)" />
                      </g>
                    </g>
                    <g id="Group_771-2" data-name="Group 771" transform="translate(1242.024 960.698)">
                      <g id="Group_770-2" data-name="Group 770" transform="translate(0 0)">
                        <path id="Path_852-2" data-name="Path 852"
                          d="M166.913,447.6a.914.914,0,0,1,.715-1.675c1.294.358.851,1.951-.289,2.179-.323.065-.187.537.136.472a1.6,1.6,0,0,0,.153-3.155A1.4,1.4,0,0,0,166.64,448c.306.163.562-.26.272-.407Z"
                          transform="translate(-165.89 -445.381)" />
                      </g>
                    </g>
                    <path id="Path_853-2" data-name="Path 853"
                      d="M149.285,528.016c-.034,6.57-.051,13.156-.085,19.725q47-.171,93.991-.325c-.085-6.472-.188-12.944-.273-19.416Q196.1,528,149.285,528.016Z"
                      transform="translate(1089.8 446)" />
                    <g id="Group_773-2" data-name="Group 773" transform="translate(1238.57 973.625)">
                      <g id="Group_772-2" data-name="Group 772">
                        <path id="Path_854-2" data-name="Path 854"
                          d="M145.6,525.351v19.107a.512.512,0,0,0,1.021,0V525.351a.512.512,0,0,0-1.021,0Z"
                          transform="translate(-145.6 -524.875)" />
                      </g>
                    </g>
                    <g id="Group_775-2" data-name="Group 775" transform="translate(1239.06 973.385)">
                      <g id="Group_774-2" data-name="Group 774">
                        <path id="Path_855-2" data-name="Path 855"
                          d="M148.973,524.376h93.6a.488.488,0,1,0,0-.976h-93.6a.488.488,0,1,0,0,.976Z"
                          transform="translate(-148.475 -523.4)" />
                      </g>
                    </g>
                    <g id="Group_777-2" data-name="Group 777" transform="translate(1332.592 973.394)">
                      <g id="Group_776-2" data-name="Group 776">
                        <path id="Path_856-2" data-name="Path 856"
                          d="M697.9,523.913q.128,9.952.255,19.92a.512.512,0,0,0,1.021,0q-.128-9.952-.255-19.92a.513.513,0,0,0-1.021,0Z"
                          transform="translate(-697.9 -523.45)" />
                      </g>
                    </g>
                    <g id="Group_779-2" data-name="Group 779" transform="translate(1238.583 993.176)">
                      <g id="Group_778-2" data-name="Group 778">
                        <path id="Path_857-2" data-name="Path 857"
                          d="M146.173,646.076h94.311a.488.488,0,1,0,0-.976H146.173a.488.488,0,1,0,0,.976Z"
                          transform="translate(-145.675 -645.1)" />
                      </g>
                    </g>
                    <text id="Bangalore_India" data-name="Bangalore, India" transform="translate(1253 988)" fill="#fff"
                      font-size="11" font-family="Noteworthy-Light, Noteworthy" font-weight="300">
                      <tspan x="0" y="0">Bangalore, India</tspan>
                    </text>
                    <g id="Group_941" data-name="Group 941" transform="translate(1528.945 965.415)">
                      <g id="Group_752-3" data-name="Group 752">
                        <path id="Path_843-3" data-name="Path 843"
                          d="M544.653,428a1.542,1.542,0,0,1,1.26-2.748,1.514,1.514,0,0,1,1.294,1.4,1.724,1.724,0,0,1-1.617,1.642c-.323.016-.323.5,0,.488a2.234,2.234,0,0,0,2.128-1.919,1.98,1.98,0,0,0-1.668-2.065,2.035,2.035,0,0,0-1.651,3.643c.255.179.511-.244.255-.439Z"
                          transform="translate(-543.535 -424.74)" />
                      </g>
                    </g>
                    <g id="Group_942" data-name="Group 942" transform="translate(1464.655 968.772)">
                      <g id="Group_770-3" data-name="Group 770" transform="translate(0 0)">
                        <path id="Path_852-3" data-name="Path 852"
                          d="M166.913,447.6a.914.914,0,0,1,.715-1.675c1.294.358.851,1.951-.289,2.179-.323.065-.187.537.136.472a1.6,1.6,0,0,0,.153-3.155A1.4,1.4,0,0,0,166.64,448c.306.163.562-.26.272-.407Z"
                          transform="translate(-165.89 -445.381)" />
                      </g>
                    </g>
                    <path id="Path_957" data-name="Path 957"
                      d="M149.3,528.016c-.04,6.57-.059,13.156-.1,19.725q54.5-.171,109-.325c-.1-6.472-.218-12.944-.317-19.416Q203.592,528,149.3,528.016Z"
                      transform="translate(1312.656 453.857)" />
                    <g id="Group_943" data-name="Group 943" transform="translate(1461.201 981.699)">
                      <g id="Group_772-3" data-name="Group 772">
                        <path id="Path_854-3" data-name="Path 854"
                          d="M145.6,525.351v19.107a.512.512,0,0,0,1.021,0V525.351a.512.512,0,0,0-1.021,0Z"
                          transform="translate(-145.6 -524.875)" />
                      </g>
                    </g>
                    <g id="Group_944" data-name="Group 944" transform="translate(1461.201 981.459)">
                      <g id="Group_774-3" data-name="Group 774">
                        <path id="Path_855-3" data-name="Path 855"
                          d="M149.054,524.376H257.89a.5.5,0,1,0,0-.976H149.054a.5.5,0,1,0,0,.976Z"
                          transform="translate(-148.475 -523.4)" />
                      </g>
                    </g>
                    <g id="Group_945" data-name="Group 945" transform="translate(1570.223 981.459)">
                      <g id="Group_776-3" data-name="Group 776">
                        <path id="Path_856-3" data-name="Path 856"
                          d="M697.9,523.91q.128,9.881.255,19.778a.512.512,0,0,0,1.021,0q-.128-9.881-.255-19.778a.514.514,0,0,0-1.021,0Z"
                          transform="translate(-697.9 -523.45)" />
                      </g>
                    </g>
                    <g id="Group_946" data-name="Group 946" transform="translate(1461.214 1001.25)">
                      <g id="Group_778-3" data-name="Group 778">
                        <path id="Path_857-3" data-name="Path 857"
                          d="M146.251,646.076H255.384a.5.5,0,1,0,0-.976H146.251a.5.5,0,1,0,0,.976Z"
                          transform="translate(-145.675 -645.1)" />
                      </g>
                    </g>
                    <text id="naveendb92_gmail.com" data-name="naveendb92@gmail.com" transform="translate(1464 996)"
                      fill="#eee" font-size="11" font-family="Noteworthy-Light, Noteworthy" font-weight="300">
                      <tspan x="0" y="0">naveendb92@gmail.com</tspan>
                    </text>
                    <g id="Group_contact_arrow" data-name="Group Contact Arrow">
                      <path id="Path_1009" data-name="Path 1009"
                        d="M363.506,505.509l-.415-.028c-.016.194-.028.39-.041.585l-1.861-.056,1.952,3.3,2.193-3.172-1.868-.056C363.479,505.888,363.49,505.7,363.506,505.509Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 476.303, 1203.095)" />
                      <path id="Path_1010" data-name="Path 1010"
                        d="M423.317,358.261l.331.227c.5-.585,1.014-1.17,1.539-1.737l-.319-.24C424.338,357.083,423.816,357.671,423.317,358.261Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 583.881, 1275.946)" />
                      <path id="Path_1011" data-name="Path 1011"
                        d="M438.272,340.911l.307.253c.552-.544,1.125-1.085,1.7-1.607l-.294-.265C439.4,339.818,438.828,340.363,438.272,340.911Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 595.043, 1290.352)" />
                      <path id="Path_1012" data-name="Path 1012"
                        d="M454.736,325l.281.276c.6-.5,1.226-.992,1.852-1.466l-.267-.287C455.971,324,455.344,324.5,454.736,325Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 604.811, 1305.674)" />
                      <path id="Path_1013" data-name="Path 1013"
                        d="M409.991,376.92l.352.2c.437-.623.9-1.246,1.364-1.853l-.341-.214C410.894,375.664,410.431,376.293,409.991,376.92Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 571.406, 1262.573)" />
                      <path id="Path_1014" data-name="Path 1014"
                        d="M472.579,310.658l.253.3c.65-.45,1.318-.891,1.987-1.313l-.238-.307C473.907,309.76,473.233,310.205,472.579,310.658Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 613.107, 1321.793)" />
                      <path id="Path_1015" data-name="Path 1015"
                        d="M398.406,396.75l.37.171c.375-.657.771-1.314,1.177-1.955l-.361-.186C399.182,395.426,398.783,396.089,398.406,396.75Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 557.709, 1250.341)" fill="#5222d0" />
                      <path id="Path_1016" data-name="Path 1016"
                        d="M376,459.6l-.4-.093c-.206.719-.4,1.452-.566,2.179l.407.077C375.6,461.046,375.794,460.318,376,459.6Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 510.371, 1221.472)" />
                      <path id="Path_1017" data-name="Path 1017"
                        d="M491.665,297.992l.223.316c.689-.4,1.4-.782,2.107-1.15l-.208-.325C493.073,297.2,492.359,297.594,491.665,297.992Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 619.865, 1338.585)" />
                      <path id="Path_1018" data-name="Path 1018"
                        d="M372,482.387l-.41-.061c-.134.732-.251,1.477-.349,2.216l.413.044C371.755,483.853,371.872,483.113,372,482.387Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 492.957, 1214.726)" />
                      <path id="Path_1019" data-name="Path 1019"
                        d="M388.661,417.6l.385.141c.308-.684.638-1.371.98-2.043l-.378-.156C389.3,416.221,388.971,416.914,388.661,417.6Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 542.892, 1239.352)" fill="#5222d0" />
                      <path id="Path_1020" data-name="Path 1020"
                        d="M380.841,439.318l.4.109c.24-.707.5-1.419.775-2.115l-.392-.125C381.345,437.888,381.083,438.605,380.841,439.318Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 527.064, 1229.694)" />
                      <path id="Path_1021" data-name="Path 1021"
                        d="M623.68,262.247c.815-.025,1.645-.036,2.467-.029l0-.375c-.827-.007-1.664,0-2.485.029Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 625.926, 1446.075)" />
                      <path id="Path_1022" data-name="Path 1022"
                        d="M511.845,287.108l.191.333c.726-.339,1.47-.668,2.21-.977l-.175-.34C513.325,286.435,512.576,286.766,511.845,287.108Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 625.029, 1355.919)" />
                      <path id="Path_1023" data-name="Path 1023"
                        d="M669.93,264.826c.811.1,1.63.225,2.434.36l.076-.368c-.809-.137-1.634-.259-2.452-.363Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 616.289, 1481.251)" />
                      <path id="Path_1024" data-name="Path 1024"
                        d="M692.685,269.109c.8.168,1.6.354,2.389.554l.113-.361c-.794-.2-1.6-.389-2.406-.558Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 609.176, 1498.046)" />
                      <path id="Path_1025" data-name="Path 1025"
                        d="M714.969,275.412c.777.231,1.559.481,2.324.743l.148-.35c-.772-.264-1.559-.516-2.342-.749Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 600.541, 1514.139)" />
                      <path id="Path_1026" data-name="Path 1026"
                        d="M646.886,262.584c.819.039,1.647.094,2.46.165l.04-.373c-.819-.071-1.653-.128-2.478-.166Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 621.835, 1463.889)" />
                      <path id="Path_1027" data-name="Path 1027"
                        d="M600.423,262.885l.051.372c.81-.09,1.636-.165,2.453-.223l-.032-.374C602.071,262.719,601.24,262.794,600.423,262.885Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 629.081, 1428.047)" fill="#5222d0" />
                      <path id="Path_1028" data-name="Path 1028"
                        d="M554.878,271.01l.123.358c.781-.218,1.579-.423,2.371-.61l-.105-.362C556.469,270.584,555.665,270.791,554.878,271.01Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 630.418, 1391.687)" />
                      <path id="Path_1029" data-name="Path 1029"
                        d="M532.961,278.094l.158.347c.756-.28,1.529-.547,2.3-.8l-.141-.353C534.5,277.543,533.723,277.813,532.961,278.094Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 628.555, 1373.657)" />
                      <path id="Path_1030" data-name="Path 1030"
                        d="M577.434,265.928l.087.367c.8-.154,1.614-.295,2.421-.417l-.069-.37C579.06,265.632,578.239,265.773,577.434,265.928Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 630.592, 1409.871)" />
                      <path id="Path_1031" data-name="Path 1031"
                        d="M739.728,279.824l-2.324-3.095-.817,1.516c-.2-.08-.394-.161-.592-.238l-.165.344c.193.075.384.154.576.231l-.813,1.509Z"
                        transform="matrix(0.208, -0.978, 0.978, 0.208, 595.844, 1529.733)" />
                    </g>
                    <g id="Group_950" data-name="Group 950"
                      transform="matrix(-0.53, -0.848, 0.848, -0.53, 1289.517, 961.384)">
                      <path id="Path_1009-2" data-name="Path 1009"
                        d="M2.476,4.295l-.443.031c-.017-.22-.029-.441-.044-.661L0,3.728,2.086,0,4.428,3.587l-2,.064C2.447,3.865,2.459,4.081,2.476,4.295Z"
                        transform="translate(0 0)" />
                      <path id="Path_1010-2" data-name="Path 1010"
                        d="M0,.257.353,0C.883.662,1.436,1.323,2,1.964l-.341.272C1.091,1.59.534.924,0,.257Z"
                        transform="translate(14.138 34.428)" />
                      <path id="Path_1011-2" data-name="Path 1011"
                        d="M0,.286.328,0c.589.616,1.2,1.227,1.819,1.818l-.315.3C1.21,1.522.594.906,0,.286Z"
                        transform="translate(17.542 38.284)" fill="#5222d0" />
                      <path id="Path_1012-2" data-name="Path 1012"
                        d="M0,.312.3,0c.645.565,1.31,1.122,1.978,1.658l-.286.324C1.32,1.443.649.881,0,.312Z"
                        transform="translate(21.289 41.841)" />
                      <path id="Path_1013-2" data-name="Path 1013"
                        d="M0,.226.375,0c.467.7.957,1.41,1.457,2.1l-.365.242C.964,1.647.47.936,0,.226Z"
                        transform="translate(11.106 30.301)" />
                      <path id="Path_1014-2" data-name="Path 1014"
                        d="M0,.336.271,0C.965.508,1.679,1.008,2.393,1.485l-.255.347C1.419,1.352.7.848,0,.336Z"
                        transform="translate(25.349 45.072)" />
                      <path id="Path_1015-2" data-name="Path 1015"
                        d="M0,.194.4,0c.4.742.824,1.486,1.258,2.211l-.386.21C.83,1.691.4.942,0,.194Z"
                        transform="translate(8.469 25.935)" />
                      <path id="Path_1016-2" data-name="Path 1016"
                        d="M1.034,2.446.6,2.552C.385,1.739.181.91,0,.087L.435,0C.615.816.817,1.639,1.034,2.446Z"
                        transform="translate(3.15 11.753)" />
                      <path id="Path_1017-2" data-name="Path 1017"
                        d="M0,.358.238,0c.736.447,1.494.884,2.25,1.3l-.222.367C1.5,1.248.742.808,0,.358Z"
                        transform="translate(29.692 47.95)" />
                      <path id="Path_1018-2" data-name="Path 1018"
                        d="M.811,2.487l-.438.069C.23,1.729.1.885,0,.05L.441,0C.545.829.669,1.666.811,2.487Z"
                        transform="translate(2.288 6.797)" />
                      <path id="Path_1019-2" data-name="Path 1019"
                        d="M0,.16.412,0c.329.773.682,1.551,1.047,2.31l-.4.177C.687,1.722.332.939,0,.16Z"
                        transform="translate(6.252 21.362)" />
                      <path id="Path_1020-2" data-name="Path 1020"
                        d="M0,.124.425,0c.256.8.535,1.6.828,2.391l-.419.142C.539,1.74.258.93,0,.124Z"
                        transform="translate(4.472 16.618)" />
                      <path id="Path_1021-2" data-name="Path 1021"
                        d="M.015,0C.886.029,1.773.04,2.65.032l0,.424C1.771.464.877.453,0,.424Z"
                        transform="translate(59.732 56.755)" />
                      <path id="Path_1022-2" data-name="Path 1022"
                        d="M0,.376.2,0C.98.383,1.774.755,2.565,1.1l-.187.384C1.582,1.137.782.763,0,.376Z"
                        transform="translate(34.285 50.454)" />
                      <path id="Path_1023-2" data-name="Path 1023"
                        d="M0,.407C.867.29,1.741.153,2.6,0l.082.417C1.817.571.935.709.062.827Z"
                        transform="translate(70.261 55.819)" />
                      <path id="Path_1024-2" data-name="Path 1024"
                        d="M0,.626C.851.437,1.71.226,2.552,0l.12.408C1.823.636.958.848.1,1.039Z"
                        transform="translate(75.439 54.676)" />
                      <path id="Path_1025-2" data-name="Path 1025"
                        d="M0,.84C.83.579,1.665.3,2.483,0l.158.4c-.824.3-1.666.584-2.5.847Z"
                        transform="translate(80.51 53.102)" />
                      <path id="Path_1026-2" data-name="Path 1026"
                        d="M0,.187C.875.143,1.759.08,2.628,0l.043.422C1.8.5.9.566.023.61Z"
                        transform="translate(65.016 56.523)" />
                      <path id="Path_1027-2" data-name="Path 1027"
                        d="M0,.421.054,0C.92.1,1.8.186,2.674.252L2.64.674C1.76.608.872.523,0,.421Z"
                        transform="translate(54.443 56.361)" />
                      <path id="Path_1028-2" data-name="Path 1028"
                        d="M0,.4.131,0C.966.247,1.818.479,2.664.69l-.112.41C1.7.887.841.653,0,.4Z"
                        transform="translate(44.078 54.257)" />
                      <path id="Path_1029-2" data-name="Path 1029"
                        d="M0,.392.169,0C.976.316,1.8.619,2.621.9l-.15.4C1.645,1.016.814.711,0,.392Z"
                        transform="translate(39.09 52.561)" />
                      <path id="Path_1030-2" data-name="Path 1030"
                        d="M0,.414.093,0C.947.175,1.817.333,2.68.472L2.606.89C1.737.75.86.59,0,.414Z"
                        transform="translate(49.211 55.527)" />
                      <path id="Path_1031-2" data-name="Path 1031"
                        d="M4.419.3,1.936,3.8,1.063,2.087c-.211.09-.422.182-.633.269L.254,1.968c.206-.085.41-.174.615-.262L0,0Z"
                        transform="translate(85.203 50.18)" />
                    </g>
                    <g id="Group_960" data-name="Group 960" transform="translate(1.475 -56.446)">
                      <g id="Group_781" data-name="Group 781" transform="translate(1028.028 1034.886)">
                        <g id="Group_780" data-name="Group 780">
                          <path id="Path_858" data-name="Path 858"
                            d="M192.8,550.864a12.879,12.879,0,0,0,1.175-4.676h-.511q-.1,6.409-.187,12.8c0,.284.511.284.511,0q.1-6.409.187-12.8c0-.284-.494-.284-.511,0a12.324,12.324,0,0,1-1.158,4.557c-.119.269.375.374.494.12Z"
                            transform="translate(-192.292 -545.975)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_783" data-name="Group 783" transform="translate(978.542 1075.042)">
                        <g id="Group_782" data-name="Group 782">
                          <path id="Path_859" data-name="Path 859"
                            d="M227.287,556.094a2.658,2.658,0,0,1-.392-2.228c.255-.894,1.464-2.017,2.264-.813.681,1.041.017,2.862-.323,3.935a15.5,15.5,0,0,1-4.086,6.553.242.242,0,0,0,.119.407c2.179.781,4.716,1.545,6.792.114.272-.179.017-.6-.255-.423-1.975,1.35-4.358.569-6.4-.163a3.656,3.656,0,0,0,.119.406,16.385,16.385,0,0,0,3.354-4.6,12.052,12.052,0,0,0,1.447-5.334c-.051-1.252-1.209-2.488-2.537-1.626a2.925,2.925,0,0,0-.545,4c.17.276.613.032.443-.228Z"
                            transform="translate(-143.679 -591.034)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_795" data-name="Group 795" transform="translate(1032.57 1037.099)">
                        <g id="Group_794" data-name="Group 794">
                          <path id="Path_865" data-name="Path 865"
                            d="M458.365,549.877a7.9,7.9,0,0,0-3.473,5.106c-.289,1.529-.306,4.293,1.5,5.025s3.234-1.61,2.571-3.073c-.63-1.382-3.592-1.561-4.052.049a.256.256,0,0,0,.494.13c.238-.813,1.515-.878,2.213-.683a1.524,1.524,0,0,1,.749,2.309,1.41,1.41,0,0,1-2.485.211,3.945,3.945,0,0,1-.613-1.935,7.38,7.38,0,0,1,3.337-6.732.236.236,0,1,0-.238-.407Z"
                            transform="translate(-454.747 -549.834)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_959" data-name="Group 959" transform="translate(-10011.862 -4821.862)">
                        <g id="Group_797" data-name="Group 797" transform="translate(11091.72 5858.5)">
                          <g id="Group_796" data-name="Group 796">
                            <path id="Path_866" data-name="Path 866"
                              d="M530.663,553.059c-.051-.114-.357-.683-.289-.894-.068-.211.051-.26.323-.163h.34c.562-.016,1.107-.016,1.668-.033,1.106-.016,2.213-.049,3.337-.065-.068-.13-.153-.244-.221-.374a34.589,34.589,0,0,0-3.609,11.546c.17-.016.341-.049.494-.065l-.051-.341c-.051-.309-.545-.179-.494.13l.051.341c.051.293.477.179.494-.065a34.167,34.167,0,0,1,3.541-11.3.252.252,0,0,0-.221-.374c-1.822.033-3.66.033-5.5.114-1.141.049-.647,1.073-.323,1.789.136.293.579.033.46-.244Z"
                              transform="translate(-529.814 -551.4)" fill="#ffffff" />
                          </g>
                        </g>
                        <g id="Group_799" data-name="Group 799" transform="translate(11093.014 5864.455)">
                          <g id="Group_798" data-name="Group 798" transform="translate(0)">
                            <path id="Path_867" data-name="Path 867"
                              d="M537.6,588.682a8.863,8.863,0,0,0,4.562-.179.245.245,0,0,0-.136-.472,8.347,8.347,0,0,1-4.29.179.246.246,0,1,0-.136.472Z"
                              transform="translate(-537.414 -588.019)" fill="#ffffff" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_958" data-name="Group 958" transform="translate(-9961.846 -4860.794)">
                        <g id="Group_801" data-name="Group 801" transform="translate(11050.786 5897.516)">
                          <g id="Group_800" data-name="Group 800">
                            <path id="Path_868" data-name="Path 868"
                              d="M589.331,555.074c-.834,1.772-1.651,3.545-2.485,5.318-.392.845-.783,1.675-1.175,2.52a15.145,15.145,0,0,1-1.192,2.537c-.187.26.255.5.443.244a16.362,16.362,0,0,0,1.124-2.39c.409-.894.834-1.773,1.243-2.667.834-1.773,1.651-3.545,2.485-5.318.136-.276-.306-.52-.443-.244Z"
                              transform="translate(-584.434 -554.952)" fill="#ffffff" />
                          </g>
                        </g>
                        <g id="Group_803" data-name="Group 803" transform="translate(11050.466 5897.064)">
                          <g id="Group_802" data-name="Group 802" transform="translate(0)">
                            <path id="Path_869" data-name="Path 869"
                              d="M587.96,552.651c-1.039-.992-2.69-.228-3.694.439-1.192.781-2.111,2.163-1.532,3.578.647,1.61,2.52,2.049,3.728,3.138.97.862,1.413,2.244.2,3.122a4.513,4.513,0,0,1-3.507.358c-.323-.065-.46.407-.136.472,1.447.26,3.1.406,4.239-.683a2.263,2.263,0,0,0-.051-3.252c-1.141-1.317-3.524-1.724-4.052-3.513-.357-1.187.528-2.26,1.5-2.878.766-.488,2.162-1.22,2.979-.455.187.244.545-.1.323-.325Z"
                              transform="translate(-582.555 -552.175)" fill="#ffffff" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_804" data-name="Group 804" transform="translate(1009.531 1036.396)">
                        <path id="Path_870" data-name="Path 870"
                          d="M628.625,548.351c.936-.878-.187-1.951-1.226-1.951-1.26.016-3.115.813-3.439,2.081a2,2,0,0,0,2.026,2.26,2.712,2.712,0,0,0,2.554-2.553c-.17.016-.341.049-.494.065a21.174,21.174,0,0,1,.306,6.635c-.119.976-.306,2.342-1.481,2.683-1.362.39-2.468-.748-3.115-1.724-.17-.26-.613-.016-.443.244a3.951,3.951,0,0,0,2.775,2.016,2.251,2.251,0,0,0,2.366-1.431,10.209,10.209,0,0,0,.511-3.919,22.3,22.3,0,0,0-.46-4.635c-.051-.293-.477-.179-.494.065a2.293,2.293,0,0,1-1.549,2,1.622,1.622,0,0,1-2.026-1.171c-.136-.846.681-1.4,1.4-1.724a5.465,5.465,0,0,1,1.107-.358,1.958,1.958,0,0,1,.868-.016c.562.179.7.537.426,1.073-.2.228.153.585.392.358Z"
                          transform="translate(-623.275 -546.4)" fill="#ffffff" />
                      </g>
                      <g id="Group_951" data-name="Group 951" transform="translate(1017.708 1036.396)">
                        <path id="Path_870-2" data-name="Path 870"
                          d="M628.625,548.351c.936-.878-.187-1.951-1.226-1.951-1.26.016-3.115.813-3.439,2.081a2,2,0,0,0,2.026,2.26,2.712,2.712,0,0,0,2.554-2.553c-.17.016-.341.049-.494.065a21.174,21.174,0,0,1,.306,6.635c-.119.976-.306,2.342-1.481,2.683-1.362.39-2.468-.748-3.115-1.724-.17-.26-.613-.016-.443.244a3.951,3.951,0,0,0,2.775,2.016,2.251,2.251,0,0,0,2.366-1.431,10.209,10.209,0,0,0,.511-3.919,22.3,22.3,0,0,0-.46-4.635c-.051-.293-.477-.179-.494.065a2.293,2.293,0,0,1-1.549,2,1.622,1.622,0,0,1-2.026-1.171c-.136-.846.681-1.4,1.4-1.724a5.465,5.465,0,0,1,1.107-.358,1.958,1.958,0,0,1,.868-.016c.562.179.7.537.426,1.073-.2.228.153.585.392.358Z"
                          transform="translate(-623.275 -546.4)" fill="#ffffff" />
                      </g>
                      <g id="Group_952" data-name="Group 952" transform="translate(1039.708 1036.395)">
                        <path id="Path_870-3" data-name="Path 870"
                          d="M628.625,548.351c.936-.878-.187-1.951-1.226-1.951-1.26.016-3.115.813-3.439,2.081a2,2,0,0,0,2.026,2.26,2.712,2.712,0,0,0,2.554-2.553c-.17.016-.341.049-.494.065a21.174,21.174,0,0,1,.306,6.635c-.119.976-.306,2.342-1.481,2.683-1.362.39-2.468-.748-3.115-1.724-.17-.26-.613-.016-.443.244a3.951,3.951,0,0,0,2.775,2.016,2.251,2.251,0,0,0,2.366-1.431,10.209,10.209,0,0,0,.511-3.919,22.3,22.3,0,0,0-.46-4.635c-.051-.293-.477-.179-.494.065a2.293,2.293,0,0,1-1.549,2,1.622,1.622,0,0,1-2.026-1.171c-.136-.846.681-1.4,1.4-1.724a5.465,5.465,0,0,1,1.107-.358,1.958,1.958,0,0,1,.868-.016c.562.179.7.537.426,1.073-.2.228.153.585.392.358Z"
                          transform="translate(-623.275 -546.4)" fill="#ffffff" />
                      </g>
                      <g id="Group_954" data-name="Group 954" transform="translate(1051.028 1036.2)">
                        <g id="Group_796-2" data-name="Group 796">
                          <path id="Path_866-2" data-name="Path 866"
                            d="M530.663,553.059c-.051-.114-.357-.683-.289-.894-.068-.211.051-.26.323-.163h.34c.562-.016,1.107-.016,1.668-.033,1.106-.016,2.213-.049,3.337-.065-.068-.13-.153-.244-.221-.374a34.589,34.589,0,0,0-3.609,11.546c.17-.016.341-.049.494-.065l-.051-.341c-.051-.309-.545-.179-.494.13l.051.341c.051.293.477.179.494-.065a34.167,34.167,0,0,1,3.541-11.3.252.252,0,0,0-.221-.374c-1.822.033-3.66.033-5.5.114-1.141.049-.647,1.073-.323,1.789.136.293.579.033.46-.244Z"
                            transform="translate(-529.814 -551.4)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_955" data-name="Group 955" transform="translate(1052.322 1042.155)">
                        <g id="Group_798-2" data-name="Group 798" transform="translate(0)">
                          <path id="Path_867-2" data-name="Path 867"
                            d="M537.6,588.682a8.863,8.863,0,0,0,4.562-.179.245.245,0,0,0-.136-.472,8.347,8.347,0,0,1-4.29.179.246.246,0,1,0-.136.472Z"
                            transform="translate(-537.414 -588.019)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_956" data-name="Group 956" transform="translate(1051.028 1036.2)">
                        <g id="Group_796-3" data-name="Group 796">
                          <path id="Path_866-3" data-name="Path 866"
                            d="M530.663,553.059c-.051-.114-.357-.683-.289-.894-.068-.211.051-.26.323-.163h.34c.562-.016,1.107-.016,1.668-.033,1.106-.016,2.213-.049,3.337-.065-.068-.13-.153-.244-.221-.374a34.589,34.589,0,0,0-3.609,11.546c.17-.016.341-.049.494-.065l-.051-.341c-.051-.309-.545-.179-.494.13l.051.341c.051.293.477.179.494-.065a34.167,34.167,0,0,1,3.541-11.3.252.252,0,0,0-.221-.374c-1.822.033-3.66.033-5.5.114-1.141.049-.647,1.073-.323,1.789.136.293.579.033.46-.244Z"
                            transform="translate(-529.814 -551.4)" fill="#ffffff" />
                        </g>
                      </g>
                      <g id="Group_957" data-name="Group 957" transform="translate(1069.399 1036.042)">
                        <path id="Path_870-4" data-name="Path 870"
                          d="M628.625,548.351c.936-.878-.187-1.951-1.226-1.951-1.26.016-3.115.813-3.439,2.081a2,2,0,0,0,2.026,2.26,2.712,2.712,0,0,0,2.554-2.553c-.17.016-.341.049-.494.065a21.174,21.174,0,0,1,.306,6.635c-.119.976-.306,2.342-1.481,2.683-1.362.39-2.468-.748-3.115-1.724-.17-.26-.613-.016-.443.244a3.951,3.951,0,0,0,2.775,2.016,2.251,2.251,0,0,0,2.366-1.431,10.209,10.209,0,0,0,.511-3.919,22.3,22.3,0,0,0-.46-4.635c-.051-.293-.477-.179-.494.065a2.293,2.293,0,0,1-1.549,2,1.622,1.622,0,0,1-2.026-1.171c-.136-.846.681-1.4,1.4-1.724a5.465,5.465,0,0,1,1.107-.358,1.958,1.958,0,0,1,.868-.016c.562.179.7.537.426,1.073-.2.228.153.585.392.358Z"
                          transform="translate(-623.275 -546.4)" fill="#ffffff" />
                      </g>
                    </g>
                    <g id="Group_962" data-name="Group 962" transform="translate(210.367 1946.016) rotate(-83)">
                      <g id="Group_807-2" data-name="Group 807"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1106.83, 1000.78)">
                        <g id="Group_806-2" data-name="Group 806">
                          <path id="Path_872-2" data-name="Path 872"
                            d="M778.586,568a8.046,8.046,0,0,1,3.643-2.716c.306-.114.17-.585-.136-.472a8.64,8.64,0,0,0-3.95,2.943c-.2.26.238.5.443.244Z"
                            transform="translate(-778.091 -564.792)" />
                        </g>
                      </g>
                      <g id="Group_809-2" data-name="Group 809"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1109.842, 1003.268)">
                        <g id="Group_808-2" data-name="Group 808" transform="translate(0)">
                          <path id="Path_873-2" data-name="Path 873"
                            d="M762.213,554.18a10.757,10.757,0,0,1,7.286-5.757.245.245,0,1,0-.136-.472,11.286,11.286,0,0,0-7.593,5.968c-.136.309.29.553.443.26Z"
                            transform="translate(-761.744 -547.942)" />
                        </g>
                      </g>
                      <g id="Group_811-2" data-name="Group 811"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1112.411, 1005.159)">
                        <g id="Group_810-2" data-name="Group 810" transform="translate(0)">
                          <path id="Path_874-2" data-name="Path 874"
                            d="M748.176,543.841a9.483,9.483,0,0,1,3.49-5.692c1.736-1.415,4.409-2.943,6.776-2.651.323.032.323-.455,0-.488-2.417-.293-5.107,1.22-6.929,2.618a9.977,9.977,0,0,0-3.83,6.066c-.051.325.443.455.494.146Z"
                            transform="translate(-747.678 -534.974)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_963" data-name="Group 963" transform="translate(2457.863 -99.41) rotate(90)">
                      <g id="Group_807-3" data-name="Group 807"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1106.83, 1000.78)">
                        <g id="Group_806-3" data-name="Group 806">
                          <path id="Path_872-3" data-name="Path 872"
                            d="M778.586,568a8.046,8.046,0,0,1,3.643-2.716c.306-.114.17-.585-.136-.472a8.64,8.64,0,0,0-3.95,2.943c-.2.26.238.5.443.244Z"
                            transform="translate(-778.091 -564.792)" />
                        </g>
                      </g>
                      <g id="Group_809-3" data-name="Group 809"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1109.842, 1003.268)">
                        <g id="Group_808-3" data-name="Group 808" transform="translate(0)">
                          <path id="Path_873-3" data-name="Path 873"
                            d="M762.213,554.18a10.757,10.757,0,0,1,7.286-5.757.245.245,0,1,0-.136-.472,11.286,11.286,0,0,0-7.593,5.968c-.136.309.29.553.443.26Z"
                            transform="translate(-761.744 -547.942)" />
                        </g>
                      </g>
                      <g id="Group_811-3" data-name="Group 811"
                        transform="matrix(-0.996, 0.087, -0.087, -0.996, 1112.411, 1005.159)">
                        <g id="Group_810-3" data-name="Group 810" transform="translate(0)">
                          <path id="Path_874-3" data-name="Path 874"
                            d="M748.176,543.841a9.483,9.483,0,0,1,3.49-5.692c1.736-1.415,4.409-2.943,6.776-2.651.323.032.323-.455,0-.488-2.417-.293-5.107,1.22-6.929,2.618a9.977,9.977,0,0,0-3.83,6.066c-.051.325.443.455.494.146Z"
                            transform="translate(-747.678 -534.974)" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutMe",
  methods: {
    onClickFB() {
      window.open("https://www.facebook.com/naveendb.jain", "_blank");
    },
    onClickInsta() {
      window.open("https://www.instagram.com/naveendb92", "_blank");
    },
  }
}
</script>
